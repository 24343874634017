@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
}
body {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  max-height: 100%;
  width: 100vw;
  max-width: 100%;
  color: #222;
}
h1 {
  font-size: 36px;
  font-weight: 900;
  line-height: 36px;
}
h2 {
  font-size: 18px;
  font-weight: 700;
}
h3 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
p {
  font-size: 14px;
  margin-bottom: 10px;
}
a {
  font-size: 14px;
  color: rgb(77, 109, 255);
}
.App {
  width: 100%;
}
.App-main {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}
.App-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
}
.App-header img:nth-child(2) {
  width: 50px;
  height: 30px;
}
.Container {
  margin: 0 auto;
  text-align: center;
}
.TextContainer {
  padding: 0;
}
.Logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.Code-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.Code {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.Code img {
  height: 200px;
}
@media only screen and (min-width: 500px) {
  .TextContainer {
    padding: 0 40px;
  }
}
